import { useEffect } from 'react';

export default function useTitle(title: string, replace?: boolean) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = replace ? title : title + ' | ' + prevTitle;
    return () => {
      document.title = prevTitle;
    };
  }, [title, replace]);
}

import { useCallback, useEffect, useRef, useState } from "react";
import { useContextController } from "context";
import { logout } from "context/actions";
import { IResponse, MethodTypes } from "types";
import { useLocation, useNavigate } from "react-router-dom";

const defaultInit: RequestInit = {
  credentials: "include"
};

const useFetch = <T>(method: MethodTypes, loader?: boolean) => {
  const [loading, setLoading] = useState(!!loader);
  const [response, setResponse] = useState<IResponse<T> | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [, dispatch] = useContextController();
  const navigate = useNavigate();
  const location = useLocation();
  const abortRef = useRef<AbortController>();

  const cancelRequest = useCallback(() => {
    try {
      abortRef.current?.abort("Canceled request");
    } catch (err) {}
  }, []);

  useEffect(() => {
    return () => {
      try {
        abortRef.current?.abort("Component Unmount");
      } catch (err) {}
    };
  }, []);

  const callFetch = useCallback(
    (input: RequestInfo, init?: RequestInit | undefined) => {
      return new Promise<IResponse<T>>((resolve, reject) => {
        const errorHandler = (err: any) => {
          // console.error('err')
          // console.error(err)
          setResponse(null);
          setError(err);
          setLoading(false);
          reject(err);
        };
        setLoading(true);
        setError(null);
        setResponse(null);
        let headers = undefined;
        if (method === "POST" || method === "PUT") {
          headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
          };
        }
        abortRef.current = new AbortController();
        init = {
          ...defaultInit,
          method,
          headers,
          signal: abortRef.current.signal,
          ...init
        };
        // console.log('final fetch input')
        // console.log(input)
        // console.log('final fetch init')
        // console.log(init)
        fetch(input, init)
          .then((res) => {
            // console.log('res')
            // console.log(res)
            res
              .json()
              .then((resJSON: IResponse<T>) => {
                setLoading(false);
                if (resJSON.success === false || res.status > 299 || res.status < 200) {
                  if (resJSON.msgCode === "UNAUTHENTICATED") {
                    logout(dispatch);
                    const params = new URLSearchParams();
                    params.set("redirect", `${location.pathname}${location.search ? location.search : ""}`);
                    navigate("/login?" + params.toString());
                  }
                  setResponse(null);
                  setError(resJSON);
                  reject(resJSON);
                } else {
                  setError(null);
                  setResponse(resJSON);
                  resolve(resJSON);
                }
              })
              .catch(errorHandler);
          })
          .catch(errorHandler);
      });
    },
    [dispatch, navigate, location, method]
  );

  return [loading, response, error, callFetch, cancelRequest] as [
    boolean,
    IResponse<T> | null,
    any,
    typeof callFetch,
    typeof cancelRequest
  ];
};

export default useFetch;

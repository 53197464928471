import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { darkTheme, lightTheme } from "configs/ThemeConfig";
import { ENDPOINTS } from "constants/ApiConstant";
import { useContextController } from "context";
import { login, updateLoading } from "context/actions";
import { useFetch } from "hooks";
import { resources } from "lang";
import { lazy, memo, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import Routes from "routes";
import useBodyClass from "utils/hooks/useBodyClass";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const [, , , callFetch] = useFetch("GET");
  const [, dispatch] = useContextController();
  const localData = localStorage.getItem("userData");

  useEffect(() => {
    (async () => {
      const localData = localStorage.getItem("userData");
      if (!localData) return;
      updateLoading(true, dispatch);
      try {
        const response = await callFetch(ENDPOINTS.account);
        if (!response?.data?.user) throw new Error("User not received.");
        login(response.data.user, dispatch);
      } catch (err) {
        localStorage.removeItem("userData");
        console.error(err);
      }
      updateLoading(false, dispatch);
    })();
  }, [dispatch, callFetch]);

  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = localData && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig = currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);

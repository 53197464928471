import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { ROUTES } from "types";

export const APP_NAME = "rEVEN";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const HOME_PREFIX_PATH = "/home";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = ROUTES.INCOMING_REQUEST;
export const PHYSICIAN_ENTRY = ROUTES.REFERRAL_REQUESTS;
export const ADMIN_ENTRY = ROUTES.ADMIN + ROUTES.DASHBOARD;
export const HOME_ENTRY = ROUTES.HOME;
export const UNAUTHENTICATED_ENTRY = ROUTES.LOGIN;

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: true
};

import React, { PropsWithChildren, createContext, useContext, useReducer } from "react";
import { ContextAction, IContext } from "types";
import reducer from "./reducer";

// Context State
const initialState: IContext = {
  userData: null,
  loading: false
};
// The Context main context
const MyContext = createContext<[IContext, React.Dispatch<ContextAction>] | null>(null);
// Setting custom name for the context which is visible on react dev tools
MyContext.displayName = "MyContext";
// Context provider
export function ContextProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <MyContext.Provider value={[state, dispatch]}>{children}</MyContext.Provider>;
}

// Context custom hook for using context
export const useContextController = () => {
  const context = useContext(MyContext);

  if (!context) {
    throw new Error("useContextController should be used inside the ContextProvider.");
  }

  return context;
};

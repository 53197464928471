import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { adminRoutes, homeRoutes, physicianRoutes, protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import { useContextController } from "context";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Route, Routes as RouterRoutes, useLocation, useNavigate } from "react-router-dom";
import { MILLISECOND, ROUTES, USER_TYPE } from "types";
import AppRoute from "./AppRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { LOCK_SCREEN } from "constants/AuthConstant";

const Routes = () => {
  const [{ userData }] = useContextController();
  const location = useLocation();
  const navigate = useNavigate();

  const handleOnActive = () => {
    //console.log('user is active', event)
    //console.log('time remaining', getRemainingTime())
  };
  const handleOnAction = () => {
    // console.log('user did something', event)
  };
  var { getTotalIdleTime } = useIdleTimer({
    timeout: MILLISECOND.MINUTE  * 30,
    onIdle: (event) => {
      if (!userData) return;
      console.log("user is idle", event);
      console.log("last active", getTotalIdleTime());
      localStorage.setItem(LOCK_SCREEN, getTotalIdleTime());
      const searchParams = new URLSearchParams();
      console.log(location.pathname);
      searchParams.append("redirect", location.pathname + (location.search ? location.search : ""));
      navigate(`${ROUTES.LOCK_SCREEN}?${searchParams.toString()}`);
    },
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  return (
    <RouterRoutes>
      {homeRoutes.map((route, index) => (
        <Route
          key={route.key + index}
          path={route.path}
          element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
        />
      ))}
      <Route path={"/"} element={<ProtectedRoute type={USER_TYPE.user} />}>
        {protectedRoutes
          // .filter((item) => item.for.includes(userData?.organizationRole))
          .map((route, index) => {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
              />
            );
          })}
      </Route>
      <Route path={"/"} element={<ProtectedRoute type={USER_TYPE.physician} />}>
        {physicianRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
            />
          );
        })}
      </Route>
      <Route path={"/"} element={<ProtectedRoute type={USER_TYPE.admin} />}>
        {adminRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
            />
          );
        })}
      </Route>
      <Route path={"/"} element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
            />
          );
        })}
      </Route>
      <Route path={"*"} element={<Navigate to={AUTHENTICATED_ENTRY} replace />} />
    </RouterRoutes>
  );
};

export default Routes;

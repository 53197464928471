import React from "react";
import { ContextAction, ContextActions, Users } from "types";

export const login = (userData: Users, dispatch: React.Dispatch<ContextAction>) =>
  dispatch({ type: ContextActions.LOGIN, value: userData });

export const logout = (dispatch: React.Dispatch<ContextAction>) => dispatch({ type: ContextActions.LOGOUT });

export const updateLoading = (loading: boolean, dispatch: React.Dispatch<ContextAction>) =>
  dispatch({ type: ContextActions.UPDATE_LOADING, value: loading });

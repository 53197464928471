import { LOCK_SCREEN, USER_DATA } from "constants/AuthConstant";
import { ContextAction, ContextActions, IContext } from "types";

// Context reducer
function reducer(state: IContext, action: ContextAction) {
  switch (action.type) {
    case ContextActions.LOGIN: {
      const user = action.value;
      localStorage.setItem(USER_DATA, JSON.stringify(user));
      localStorage.removeItem(LOCK_SCREEN);
      return { ...state, userData: action.value };
    }
    case ContextActions.LOGOUT: {
      localStorage.removeItem(USER_DATA);
      localStorage.removeItem(LOCK_SCREEN);
      return { ...state, userData: null };
    }
    case ContextActions.UPDATE_LOADING: {
      return { ...state, loading: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

export default reducer;

import React from "react";
import { Navigate } from "react-router-dom";
import { ORGANIZATION_ROLE, ROUTES } from "types";
import { AUTHENTICATED_ENTRY } from "./AppConfig";

export const publicRoutes = [
  {
    key: ROUTES.LOGIN,
    path: ROUTES.LOGIN,
    component: React.lazy(() => import("pages/login"))
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    component: React.lazy(() => import("pages/forgot-password"))
  },
  {
    key: ROUTES.FORGOT_PASSWORD_TOKEN,
    path: ROUTES.FORGOT_PASSWORD_TOKEN,
    component: React.lazy(() => import("pages/forgot-password/token"))
  },
  {
    key: ROUTES.FORGOT_PASSWORD_UPDATE,
    path: ROUTES.FORGOT_PASSWORD_UPDATE,
    component: React.lazy(() => import("pages/forgot-password/password"))
  }
];

export const adminRoutes = [
  {
    key: ROUTES.ADMIN + ROUTES.ASTERISK,
    path: ROUTES.ADMIN + ROUTES.ASTERISK,
    component: React.lazy(() => import("pages/admin")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: ROUTES.LOCK_SCREEN,
    path: ROUTES.LOCK_SCREEN,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    meta: {
      blankLayout: true
    },
    component: React.lazy(() => import("pages/lock-screen"))
  }
];

export const physicianRoutes = [
  {
    key: ROUTES.REFERRAL_REQUESTS,
    path: ROUTES.REFERRAL_REQUESTS,
    component: React.lazy(() => import("pages/referral-requests"))
  },
  {
    key: ROUTES.LOCK_SCREEN,
    path: ROUTES.LOCK_SCREEN,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    meta: {
      blankLayout: true
    },
    component: React.lazy(() => import("pages/lock-screen"))
  }
];

export const protectedRoutes = [
  {
    key: ROUTES.DASHBOARD,
    path: ROUTES.DASHBOARD,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/dashboard"))
  },
  {
    key: ROUTES.INCOMING_REQUEST,
    path: ROUTES.INCOMING_REQUEST,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    component: React.lazy(() => import("pages/incoming-requests"))
  },
  {
    key: ROUTES.FAXES,
    path: ROUTES.FAXES,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    component: React.lazy(() => import("pages/faxes"))
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    component: React.lazy(() => import("pages/search"))
  },
  {
    key: ROUTES.LOCATIONS,
    path: ROUTES.LOCATIONS,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/locations"))
  },
  {
    key: ROUTES.ORGANIZATION,
    path: ROUTES.ORGANIZATION,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/organization"))
  },
  {
    key: ROUTES.PHYSICIANS,
    path: ROUTES.PHYSICIANS,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/physicians"))
  },
  {
    key: ROUTES.USERS,
    path: ROUTES.USERS,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/users"))
  },
  {
    key: ROUTES.LOCK_SCREEN,
    path: ROUTES.LOCK_SCREEN,
    for: [ORGANIZATION_ROLE.admin, ORGANIZATION_ROLE.support],
    meta: {
      blankLayout: true
    },
    component: React.lazy(() => import("pages/lock-screen"))
  },
  {
    key: ROUTES.SETTINGS + ROUTES.ASTERISK,
    path: ROUTES.SETTINGS + ROUTES.ASTERISK,
    for: [ORGANIZATION_ROLE.admin],
    component: React.lazy(() => import("pages/settings"))
  },
  {
    key: ROUTES.PROFILE,
    path: ROUTES.PROFILE,
    component: React.lazy(() => import("pages/profile"))
  },
  {
    key: ROUTES.ADMIN_UPLOAD,
    path: ROUTES.ADMIN_UPLOAD,
    component: React.lazy(() => import("pages/admin-upload")),
    meta: {
      blankLayout: true
    }
  }
];

export const homeRoutes = [
  {
    key: ROUTES.HOME,
    path: ROUTES.HOME,
    component: () => <Navigate to={AUTHENTICATED_ENTRY} />,
    meta: {
      blankLayout: true
    }
  },
  {
    key: ROUTES.PATIENT_UPLOAD + ROUTES.ASTERISK,
    path: ROUTES.PATIENT_UPLOAD + ROUTES.ASTERISK,
    component: React.lazy(() => import("pages/patient-upload")),
    meta: {
      blankLayout: true
    }
  }
];

export enum HEX_OPACITY {
  FIFTY = "0D", // 0.05
  ONE = "1A", // 0.1
  TWO = "33", // 0.2
  THREE = "4D", // 0.3
  FOUR = "66", // 0.4
  FIVE = "80", // 0.5
  SIX = "99", // 0.6
  SEVEN = "B3", // 0.7
  EIGHT = "CC", // 0.8
  NINE = "E6" // 0.9
}
export enum MILLISECOND {
  SECOND = 1000,
  MINUTE = SECOND * 60,
  HOUR = MINUTE * 60,
  DAY = HOUR * 24,
  WEEK = DAY * 7,
  MONTH = DAY * 30,
  YEAR = DAY * 365
}
export enum BYTE {
  BYTE = 1,
  KB = BYTE * 1024,
  MB = KB * 1024,
  GB = MB * 1024,
  TB = GB * 1024
}
export enum USER_TYPE {
  admin = "admin",
  user = "user",
  physician = "physician"
}
export const USER_TYPE_LABELS = {
  [USER_TYPE.admin]: "Super Admin",
  [USER_TYPE.user]: "User",
  [USER_TYPE.physician]: "Physician"
};
export enum ORGANIZATION_ROLE {
  admin = "admin",
  support = "support"
}
export enum STATUS {
  active = "active",
  inactive = "inactive"
}
export enum FAX_STATUS {
  sent = "sent",
  failed = "failed"
}
export enum FILE_MIME_TYPE {
  png = "image/png",
  jpg = "image/jpg",
  jpeg = "image/jpeg",
  pdf = "application/pdf"
}
export enum FILE_EXTENSION {
  png = ".png",
  jpg = ".jpg",
  jpeg = ".jpeg",
  pdf = ".pdf"
}
export enum USER_TIME_DURATION {
  today = "Today",
  yesterday = "Yesterday",
  lastWeek = "Last Week",
  thisWeek = "This Week",
  thisMonth = "This Month",
  lastMonth = "Last Month",
  custom = "Custom"
}
export enum DIRECTION {
  inbound = "inbound",
  outbound = "outbound"
}
export enum ROUTES {
  ASTERISK = "/*",
  HOME = "/",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  FORGOT_PASSWORD_TOKEN = "/forgot-password/token",
  FORGOT_PASSWORD_UPDATE = "/forgot-password/password",
  ADMIN = "/admin",
  DASHBOARD = "/dashboard",
  ORGANIZATIONS = "/organizations",
  USERS = "/users",
  CHAT = "/chat",
  INCOMING_REQUEST = "/incoming-requests",
  REFERRAL_REQUESTS = "/referral-requests",
  FAXES = "/faxes",
  SEARCH = "/search",
  LOCATIONS = "/locations",
  ORGANIZATION = "/organization",
  PHYSICIANS = "/physicians",
  SETTINGS = "/settings",
  QR_CODE_GENERATOR = "/qr-code-generator",
  LOCK_SCREEN = "/lock-screen",
  PATIENT_UPLOAD = "/patient-upload",
  ADMIN_UPLOAD = "/admin-upload",
  PROFILE = "/profile"
}
export enum DOCUMENT_VARIANT {
  default = "default",
  pathologyTests = "pathology_tests",
  prepaidToxicology = "prepaid_toxicology",
  foodEnvironmentTests = "food_environment_tests",
  results = "results"
}
export const DOCUMENT_VARIANT_COLORS = {
  [DOCUMENT_VARIANT.default]: "gold",
  [DOCUMENT_VARIANT.pathologyTests]: "cyan",
  [DOCUMENT_VARIANT.prepaidToxicology]: "red",
  [DOCUMENT_VARIANT.foodEnvironmentTests]: "blue",
  [DOCUMENT_VARIANT.results]: "indigo"
};
export const DOCUMENT_VARIANT_LABELS = {
  [DOCUMENT_VARIANT.default]: "Default",
  [DOCUMENT_VARIANT.pathologyTests]: "Pathology",
  [DOCUMENT_VARIANT.prepaidToxicology]: "Prepaid Toxicology",
  [DOCUMENT_VARIANT.foodEnvironmentTests]: "Food Environment",
  [DOCUMENT_VARIANT.results]: "Results"
};

export const baseURL = process.env.REACT_APP_BASE_URL;
// export const baseURL = "http://192.168.18.117:3070";
export const apiURL = baseURL + "/api/v1";
export const ENDPOINTS = {
  account: apiURL + "/account",
  login: apiURL + "/account/login",
  forget: apiURL + "/account/forget",
  token: apiURL + "/account/token",
  users: apiURL + "/users",
  userLocations: apiURL + "/user-locations",
  documents: apiURL + "/documents",
  patientTextExtract: apiURL + "/documents/patient/text-extract",
  patientUpload: apiURL + "/documents/patient/upload",
  faxes: apiURL + "/faxes",
  locations: apiURL + "/locations",
  signed: apiURL + "/signed",
  signedPublic: apiURL + "/signed-public",
  signedJSON: apiURL + "/signed-json",
  organizations: apiURL + "/organizations",
  modalities: apiURL + "/modalities",
  organizationBins: apiURL + "/organization-bins"
};

export const PASSWORD_RULES =
  "Password must be minimum 8 characters long and include 1 digit, 1 uppercase, 1 lowercase and 1 special character.";
export const placeholderImage = "/img/avatars/thumb-1.jpg";

export const AppDatePreference = ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

import { ENDPOINTS } from "constants/ApiConstant";
import { useContextController } from "context";
import { login } from "context/actions";
import { useCallback } from "react";
import { Users } from "types";
import useFetch from "./useFetch";

export default function useProfile() {
  const [loading, , , callFetch] = useFetch<{ user?: Users }>("GET");
  const [, dispatch] = useContextController();

  const updateProfile = useCallback(async () => {
    return await callFetch(ENDPOINTS.account)
      .then((res) => {
        const user = res.data?.user;
        if (user) {
          login(user, dispatch);
        }
        return user;
      })
      .catch(console.error);
  }, [dispatch, callFetch]);

  return [updateProfile, loading] as [typeof updateProfile, boolean];
}

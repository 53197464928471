import {
  ADMIN_ENTRY,
  AUTHENTICATED_ENTRY,
  PHYSICIAN_ENTRY,
  REDIRECT_URL_KEY,
  UNAUTHENTICATED_ENTRY
} from "configs/AppConfig";
import { LOCK_SCREEN, USER_DATA } from "constants/AuthConstant";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES, USER_TYPE, Users } from "types";

const ProtectedRoute = ({ type }: { type?: USER_TYPE }) => {
  const location = useLocation();
  const userData: Users = JSON.parse(localStorage.getItem(USER_DATA) || "null");
  const lockedValue = localStorage.getItem(LOCK_SCREEN);
  if (!userData) {
    return (
      <Navigate
        to={`${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}${
          location.search ? location.search : ""
        }`}
        replace
      />
    );
  } else if (lockedValue && location.pathname !== ROUTES.LOCK_SCREEN) {
    return (
      <Navigate
        to={`${ROUTES.LOCK_SCREEN}?${REDIRECT_URL_KEY}=${location.pathname}${location.search ? location.search : ""}`}
        replace
      />
    );
  }
  // console.log("userData", userData?.type);
  if (userData?.type && userData?.type !== type) {
    return (
      <Navigate
        to={
          userData.type === USER_TYPE.admin
            ? ADMIN_ENTRY
            : userData.type === USER_TYPE.physician
            ? PHYSICIAN_ENTRY
            : AUTHENTICATED_ENTRY
        }
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;

import { ADMIN_ENTRY, AUTHENTICATED_ENTRY, PHYSICIAN_ENTRY } from "configs/AppConfig";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { USER_TYPE, Users } from "types";

const PublicRoute = () => {
  const userData: Users = JSON.parse(localStorage.getItem("userData") || "null");
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  return userData ? (
    <Navigate
      to={
        redirectUrl
          ? redirectUrl
          : userData.type === USER_TYPE.admin
          ? ADMIN_ENTRY
          : userData.type === USER_TYPE.physician
          ? PHYSICIAN_ENTRY
          : AUTHENTICATED_ENTRY
      }
    />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;

import { Users } from "./models";

// An enum with all the types of actions to use in our reducer
export enum ContextActions {
  LOGIN = "login",
  LOGOUT = "logout",
  UPDATE_LOADING = "update-loading"
}

// An interface for our actions
export type ContextAction =
  | {
      type: ContextActions.LOGIN;
      value: Users;
    }
  | {
      type: ContextActions.LOGOUT;
    }
  | {
      type: ContextActions.UPDATE_LOADING;
      value: boolean;
    };

// Context Values
export interface IContext {
  userData: Users | null;
  loading?: boolean;
}
